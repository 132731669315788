import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import interior from '../images/home/cookscape-interior-designs.webp';
import blog1 from '../images/blogs/blog-images/cookscape-blog-1 .webp';
import blog2 from '../images/blogs/blog-images/cookscape-blog-2 .webp';
import blog3 from '../images/blogs/blog-images/cookscape-blog-3 .webp';
import blog4 from '../images/blogs/blog-images/cookscape-blog-4 .webp';
import blog5 from '../images/blogs/blog-images/cookscape-blog-5 .webp';
import blog6 from '../images/blogs/blog-images/cookscape-blog-6 .webp';
import blog7 from '../images/blogs/blog-images/cookscape-blog-7 .webp';
import blog8 from '../images/blogs/blog-images/cookscape-blog-8 .webp';
import blog9 from '../images/blogs/blog-images/cookscape-blog-9 .webp';
import blog10 from '../images/blogs/blog-images/cookscape-blog-10 .webp';

import armanikandan from '../images/blogs/architects/Cookscape--Architect-manikandan .webp';
import ardinesh from '../images/blogs/architects/Cookscape--Architect-dinesh .webp';
import arraakesh from '../images/blogs/architects/Cookscape--Architect-raakesh-natarajan .webp';
import arnavanitha from '../images/blogs/architects/Cookscape--Architect-navanitha .webp';
import arbhaghyasri from '../images/blogs/architects/Cookscape-Architect-bhagyashri .webp';
import arakila from '../images/blogs/architects/Cookscape--Architect-Akila .webp';
import arvarshini from '../images/blogs/architects/Cookscape--Architect-varshini .webp';
import argopal from '../images/blogs/architects/Cookscape-Architect-madhana-gopal .webp';
import arkeerthika from '../images/blogs/architects/Cookscape--Architect-keerthika .webp';
function Blog() {
  return (
<>
<Helmet>
<title> Cookscape - Interior Designer in Chennai, Modular Kitchen Designer in Chennai, Wardrobe Designer in Chennai, Home Renovation in Chennai, TV Unit in  Chennai, Kitchen Cabinet Designs In Chennai  </title>
<meta name="keywords" content="interior designers in Chennai, modular kitchen in Chennai, interior decorators in Chennai, kitchen designers in Chennai, bedroom wardrobe designers in Chennai, home renovation in Chennai, remodeling in Chennai, kitchen renovators in Chennai, living room interior decor in Chennai, dining room interior designers in Chennai, home interior decors in Chennai, interior design company in Chennai, interior design ideas in Chennai, kitchen interior ideas in Chennai, pooja room interior designers in Chennai, kitchen cabinet designs in Chennai"/>
<meta name="description" content="Cookscape offers expert interior design services, including modular kitchens, pooja units, wardrobes, TV units, kids' furniture, and more.
"/>
<meta name='copyright' content='Cookscape'/>
<meta name='language' content='IN'/>
<meta name='robots' content='index,follow'/>
<meta name='topic' content='Business'/>
<meta name='Classification' content='Interior Designer'/>
<meta name='author' content='info@cookscape.com'/>
<meta name='designer' content='Cookscape India Private Limited'/>
<meta name='reply-to' content='Cookscape'/>
<meta name='owner' content='Cookscape India Private Limited'/>
<link rel="canonical" href="https://www.cookscape.com/blog"></link>
</Helmet>
<div className="container-fluid services-breadcrumb">
        <h3 className="cookscape-services-title">Cookscape Blog</h3>
</div>
<div className="container-fluid background">
        <div className="container">
            <div className="blog-row p-3">
                <div className="row p-3">
                    <div className="col-md-3">
                        <img src={blog1} alt="" class="blog-img"/>
                    </div>
                    <div className="col-md-6">
                         <h1 className='blog-title pt-3'> Understanding the Theme and Concept in Interior Design</h1>
                         <p className='blog-para'> Interior design is a profoundly proficient and enormous field where every creator follows an alternate example in a specific entryway. Each architect has their own process of designing from origination to the last completing of the venture.</p>
                        <Link to="/understanding-the-theme-and-concept-in-interior-design"><button class="aboutus-button-2">View more</button></Link>
                    </div>
                    <div className="col-md-3 ar">
                        <img src={armanikandan} alt="" class="ar-img"/>
                        <h1 className='blog-title pt-3'> Architect Manikandan</h1>
                    </div>
                </div>
                <div className="row p-3">
                    <div className="col-md-3">
                        <img src={blog2} alt="" class="blog-img"/>
                    </div>
                    <div className="col-md-6">
                        <h1 className='blog-title pt-3'> Clever Design Solutions for a Rain Water Friendly Balcony!</h1>
                        <p className='blog-para'> To free floor space books and artefacts could be placed high up 12 to 18 inches below ceiling. This space is always underutilized and with the placement of sturdy ledges it can not only become a functional storage but also an aesthetically appealing design element. </p>
                        <Link to="/clever-design-solutions-for-a-rain-water-friendly-balcony"><button class="aboutus-button-2">View more</button></Link>
                    </div>
                     <div className="col-md-3 ar">
                        <img src={ardinesh} alt="" class="ar-img"/>
                        <h1 className='blog-title pt-3'> Architect Dinesh</h1>
                    </div>
                </div>
                <div className="row p-3">
                    <div className="col-md-3">
                        <img src={blog3} alt="" class="blog-img"/>
                    </div>
                    <div className="col-md-6">
                        <h1 className='blog-title pt-3'> 10 Major Kitchen Storage Mistakes To Avoid!</h1>
                        <p className='blog-para'> Attempt to utilize wall spaces actually. Cupboards can be laid into the wall to permit really working space. In the event that you are thinking about ceramics cupboards, an inbuilt one or a wall-mounted one is smart. It likewise goes about as a critical stylistic layout component.</p>
                        <Link to="/10-major-kitchen-storage-mistakes-to-avoid"><button class="aboutus-button-2">View more</button></Link>
                    </div>
                    <div className="col-md-3 ar">
                        <img src={arraakesh} alt="" class="ar-img"/>
                        <h1 className='blog-title pt-3'> Architect Raakesh Natarajan</h1>
                    </div>
                </div>
                <div className="row p-3">
                    <div className="col-md-3 ar">
                        <img src={blog4} alt="" class="blog-img"/>
                    </div>
                    <div className="col-md-6">
                        <h1 className='blog-title pt-3'> What businesses can be combined with Interior Designing Business?</h1>
                        <p className='blog-para'> The one business that can be joined with Interior Designing Business is selling furniture at the best quality. Assembling your own furniture perhaps be the most ideal alternative which really accommodates your own prerequisites and style.</p>
                        <Link to="/what-businesses-can-be-combined-with-interior-designing-business"><button class="aboutus-button-2">View more</button></Link>
                    </div>
                    <div className="col-md-3">
                        <img src={arnavanitha} alt="" class="ar-img"/>
                        <h1 className='blog-title pt-3'> Architect Navanitha Ramesh</h1>
                    </div>
                </div>
                <div className="row p-3">
                    <div className="col-md-3">
                        <img src={blog5} alt="" class="blog-img"/>
                    </div>
                    <div className="col-md-6">
                        <h1 className='blog-title pt-3'> 7 Amazing Wooden Ceiling Designs You Will Love!</h1>
                        <p className='blog-para'> It provides a bunch of functional benefits, in addition to uplifting the look of your home. Modern false ceiling design for hall or even office false ceiling design is a great way to conceal the jungle of electric wires, pipes and air-conditioning ducts.</p>
                        <Link to="/7-amazing-wooden-ceiling-designs-you-will-love" ><button class="aboutus-button-2">View more</button></Link>
                    </div>
                    <div className="col-md-3 ar">
                        <img src={arbhaghyasri} alt="" class="ar-img"/>
                        <h1 className='blog-title pt-3'> Architect Bhaghyashri</h1>
                    </div>
                </div>
                <div className="row p-3">
                    <div className="col-md-3">
                        <img src={blog6} alt="" class="blog-img"/>
                    </div>
                    <div className="col-md-6">
                        <h1 className='blog-title pt-3'> How To Prevent Dampness From The Wall!</h1>
                        <p className='blog-para'> Condensation acts as the major cause of dampness in many homes. Majorly the kitchens, the bathrooms and bedrooms are the most affected areas caused by the day to day house old activities like cooking, washing and drying clothes.</p>
                        <Link to="/how-to-prevent-dampness-from-the-wall"><button class="aboutus-button-2">View more</button></Link>
                    </div>
                    <div className="col-md-3 ar">
                        <img src={arakila} alt="" class="ar-img"/>
                        <h1 className='blog-title pt-3'> Architect Akila</h1>
                    </div>
                </div>
                <div className="row p-3">
                    <div className="col-md-3">
                        <img src={blog7} alt="" class="blog-img"/>
                    </div>
                    <div className="col-md-6">
                        <h1 className='blog-title pt-3'> Smarter Interior design for the Smart Homes!</h1>
                        <p className='blog-para'> Remote systems administration, remote charging… We wouldn’t fault you if you had any desire to hop directly into the universe of remote innovation. It’s cool, it’s tomfoolery and it basically seems like less of underlying speculation.</p>
                        <Link to="/smarter-interior-design-for-the-smart-homes"><button class="aboutus-button-2">View more</button></Link>
                    </div>
                    <div className="col-md-3 ar">
                        <img src={arvarshini} alt="" class="ar-img"/>
                        <h1 className='blog-title pt-3'> Architect Varshini</h1>
                    </div>
                </div>
                <div className="row p-3">
                    <div className="col-md-3">
                        <img src={blog8} alt="" class="blog-img"/>
                    </div>
                    <div className="col-md-6">
                        <h1 className='blog-title pt-3'> 12 Reasons to love Chalk Paint!</h1>
                        <p className='blog-para'> Have you ever wondered about chalk paint?? No no not the chalkboard paint… It’s something beyond that! Here you’ll find 12 reasons love chalk paint which is a hit with designers and DIYers so you can make an informed choice for your Home Interiors!</p>
                        <Link to="/12-reasons-to-love-chalk-paint"><button class="aboutus-button-2">View more</button></Link>
                    </div>
                    <div className="col-md-3 ar">
                        <img src={argopal} alt="" class="ar-img"/>
                        <h1 className='blog-title pt-3'> Architect CRE Madhana Gopal R</h1>
                    </div>
                </div>
                <div className="row p-3">
                    <div className="col-md-3">
                        <img src={blog9} alt="" class="blog-img"/>
                    </div>
                    <div className="col-md-6">
                        <h1 className='blog-title pt-3'> Indoor Decoration Ideas with Money Plant!</h1>
                        <p className='blog-para'> Money Plant! Beautiful, hardy, strong and popular house plants. They are versatile and beautifully grow in all the conditions. Easily available everywhere money plants are easy to take care. These lovely plants make for beautiful home decor and there is a lot that one can do with them.</p>
                        <Link to="/indoor-decoration-ideas-with-money-plant"><button class="aboutus-button-2">View more</button></Link>
                    </div>
                    <div className="col-md-3 ar">
                        <img src={arkeerthika} alt="" class="ar-img"/>
                        <h1 className='blog-title pt-3'> Architect Keerthika G</h1>
                    </div>
                </div>
                <div className="row p-3">
                    <div className="col-md-3">
                        <img src={blog10} alt="" class="blog-img"/>
                    </div>
                    <div className="col-md-6">
                        <h1 className='blog-title pt-3'> How can persuasive design be used in interior design?</h1>
                        <p className='blog-para'> Interior design is a profoundly proficient and enormous field where every creator follows an alternate example in a specific entryway. Each architect has their own process of designing from origination to the last completing of the venture.</p>
                        <Link to="/how-can-persuasive-design-be-used-in-interior-design"><button class="aboutus-button-2">View more</button></Link>
                    </div>
                    <div className="col-md-3 ar">
                        <img src={arbhaghyasri} alt="" class="ar-img"/>
                        <h1 className='blog-title pt-3'> Architect Bhaghyashri</h1>
                    </div>
                </div>
            </div>
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-2'></div>
                <div className='col-md-8'>
                    <div class="pagination  align-items-center">
                        <Link to="/blog"> <a class="active">&laquo;</a></Link>
                        <Link to="/blog">  <a class="active">1</a></Link>
                        <Link to="/blog-2"> <a>2</a></Link>
                        <Link to="/blog-3"> <a>3</a></Link>
                        <Link to="/blog-4"> <a>4</a></Link>
                        <Link to="/blog-5"> <a>5</a></Link>
                        <Link to="/blog-6"> <a>6</a></Link>
                        <Link to="/blog-7"> <a>7</a></Link>
                        <Link to="/blog-8"> <a>8</a></Link>
                        <Link to="/blog-8"><a>&raquo;</a></Link>
                    </div>
                </div>
                <div className='col-md-2'></div>
            </div>
        </div>
</div>
<div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get Free Design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
            <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
</div>
</>
  )
}
export default Blog;