import React from 'react';
import { Helmet } from 'react-helmet';
import interior from '../images/home/cookscape-interior-designs.webp';
import img1 from '../images/services/bed-room.png';
import img2 from '../images/services/bedroom-1.png';
import img3 from '../images/services/bed-room2.png';
const Variants = () => {
  return (
   <>
<Helmet>
<title> Cookscape - Interior Designer in Chennai, Modular Kitchen Designer in Chennai, Wardrobe Designer in Chennai, Home Renovation in Chennai, TV Unit in  Chennai, Kitchen Cabinet Designs In Chennai  </title>
<meta name="keywords" content="interior designers in Chennai, modular kitchen in Chennai, interior decorators in Chennai, kitchen designers in Chennai, bedroom wardrobe designers in Chennai, home renovation in Chennai, remodeling in Chennai, kitchen renovators in Chennai, living room interior decor in Chennai, dining room interior designers in Chennai, home interior decors in Chennai, interior design company in Chennai, interior design ideas in Chennai, kitchen interior ideas in Chennai, pooja room interior designers in Chennai, kitchen cabinet designs in Chennai"/>
<meta name="description" content="Cookscape offers expert interior design services, including modular kitchens, pooja units, wardrobes, TV units, kids' furniture, and more.
"/>
<meta name='copyright' content='Cookscape'/>
<meta name='language' content='IN'/>
<meta name='robots' content='index,follow'/>
<meta name='topic' content='Business'/>
<meta name='Classification' content='Interior Designer'/>
<meta name='author' content='info@cookscape.com'/>
<meta name='designer' content='Cookscape India Private Limited'/>
<meta name='reply-to' content='Cookscape'/>
<meta name='owner' content='Cookscape India Private Limited'/>
<link rel="canonical" href="https://www.cookscape.com/premium-variants"></link>
</Helmet>
<div className="container-fluid services-breadcrumb">
        <h3 className="cookscape-services-title">Cookscape Premium Variants</h3>
</div>
<div className="container-fluid background">
      <div className="container">
         <div className="row">
         <div className="col-md-6 mt-3">
       
         <p className="experience">Living Space Ideas</p>
         <h1 className="aboutus-title">Budget <span className='cookscape'>3 Lakhs to 4 Lakhs</span></h1>
         <p className='blog-page-para'> • By scope:</p>
         <p className='blog-page-para'> • By scope:</p>
         <p className='blog-page-para'> • By scope:</p>
         <p className='blog-page-para'> • By scope:</p>
            </div> 
            <div className="col-md-6 mt-3">
               <img src={img1} alt="cookscape-floor-planning" class="img-fluid"/>
            </div> 
      </div>

      <div className="row">
         <div className="col-md-6 mt-3">
       
         <p className="experience">Living Space Ideas</p>
         <h1 className="aboutus-title">Budget <span className='cookscape'>3 Lakhs to 4 Lakhs</span></h1>
         <p className='blog-page-para'> • By scope:</p>
         <p className='blog-page-para'> • By scope:</p>
         <p className='blog-page-para'> • By scope:</p>
         <p className='blog-page-para'> • By scope:</p>
            </div> 
            <div className="col-md-6 mt-3">
               <img src={img2} alt="cookscape-floor-planning" class="img-fluid"/>
            </div> 
      </div>

      <div className="row">
         <div className="col-md-6 mt-3">
       
         <p className="experience">Living Space Ideas</p>
         <h1 className="aboutus-title">Budget <span className='cookscape'>3 Lakhs to 4 Lakhs</span></h1>
         <p className='blog-page-para'> • By scope:</p>
         <p className='blog-page-para'> • By scope:</p>
         <p className='blog-page-para'> • By scope:</p>
         <p className='blog-page-para'> • By scope:</p>
            </div> 
            <div className="col-md-6 mt-3">
               <img src={img3} alt="cookscape-floor-planning" class="img-fluid"/>
            </div> 
      </div>
    </div>
</div>
<div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
                    <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
</div>
   </>
  );
};

export default Variants;
