// Navbar.js
import React from 'react';
import img1 from '../images/factory/1.png';
import img2 from '../images/factory/2.png';
import img3 from '../images/factory/3.png';
import icon1 from '../images/icon/icon1.png';

import interior from '../images/home/cookscape-interior-designs.webp';
import renovation1 from '../images/factory/1.png';
import renovation2 from '../images/factory/2.png';
import renovation3 from '../images/factory/3.png';
import renovation4 from '../images/gallery/renovation/cookscape-renovation-project-4.webp';
import renovation5 from '../images/gallery/renovation/cookscape-renovation-project-5.webp';
import renovation6 from '../images/gallery/renovation/cookscape-renovation-project-6.webp';
const Factory = () => {
  return (
    <>
    <div className="container-fluid factory-breadcrumb">
        <div className='factory-main'>
        <h2 className='factory-main-title'>Interior Design Doesn't Have to Be Expensive or Complicated !  <br/>It Should Be Smart & User-friendly</h2>
        </div>
</div>

<div className='container mt-5'>
    <div className='row'>
        <div className='col-md-4 p-3'>
        <img src={icon1} alt="cookscape-interior-design-ideas" class='img-fluid'/>
                <h2 className="factory-icon-title">3 Decades of Experience</h2>
                <p className="factory-icon-para">Lorem ipsum dolor sit amet consectetur adipisicing elit. Id, minima. It is a long established fact that a reader will be distracted by the readable.​</p>
        </div>
        <div className='col-md-4 p-3'>
        <img src={icon1} alt="cookscape-interior-design-ideas" class='img-fluid'/>
                <h2 className="factory-icon-title">3 Decades of Experience</h2>
                <p className="factory-icon-para">Lorem ipsum dolor sit amet consectetur adipisicing elit. Id, minima. It is a long established fact that a reader will be distracted by the readable.​</p>
        </div>
        <div className='col-md-4 p-3'>
        <img src={icon1} alt="cookscape-interior-design-ideas" class='img-fluid'/>
                <h2 className="factory-icon-title">3 Decades of Experience</h2>
                <p className="factory-icon-para">Lorem ipsum dolor sit amet consectetur adipisicing elit. Id, minima. It is a long established fact that a reader will be distracted by the readable.​</p>
        </div>
    </div>
    <div className='row pt-3'>
        <div className='col-md-4 p-3'>
        <img src={icon1} alt="cookscape-interior-design-ideas" class='img-fluid'/>
                <h2 className="factory-icon-title">3 Decades of Experience</h2>
                <p className="factory-icon-para">Lorem ipsum dolor sit amet consectetur adipisicing elit. Id, minima. It is a long established fact that a reader will be distracted by the readable.​</p>
        </div>
        <div className='col-md-4 p-3'>
        <img src={icon1} alt="cookscape-interior-design-ideas" class='img-fluid'/>
                <h2 className="factory-icon-title">3 Decades of Experience</h2>
                <p className="factory-icon-para">Lorem ipsum dolor sit amet consectetur adipisicing elit. Id, minima. It is a long established fact that a reader will be distracted by the readable.​</p>
        </div>
        <div className='col-md-4 p-3'>
        <img src={icon1} alt="cookscape-interior-design-ideas" class='img-fluid'/>
                <h2 className="factory-icon-title">3 Decades of Experience</h2>
                <p className="factory-icon-para">Lorem ipsum dolor sit amet consectetur adipisicing elit. Id, minima. It is a long established fact that a reader will be distracted by the readable.​</p>
        </div>
    </div>
    </div>

  


    <div className='container'>
      <h3 class="services-title-2">Factory Images</h3>
          <div class="row justify-content-center">
              <div class="row justify-content-center">
                  <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                      <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="0" src={renovation1} alt="" class="img-fluid"/>
                  </a>
                  <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                      <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="1" src={renovation2} alt="" class="img-fluid"/>
                  </a>
                  <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                      <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="2" src={renovation3} alt="" class="img-fluid"/>
                  </a>
                </div>
              <div class="row justify-content-center pt-3">
                  <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                      <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="3" src={renovation4} alt="" class="img-fluid"/>
                  </a>
                  <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                      <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="4" src={renovation5} alt="" class="img-fluid"/>
                  </a>
                  <a href="#" class="col-sm-4" data-bs-toggle="modal" data-bs-target="#exampleLightbox">
                      <img data-bs-target="#lightboxExampleCarousel" data-bs-slide-to="5" src={renovation6} alt="" class="img-fluid"/>
                  </a>
              </div>
            </div>
            <div class="modal fade" id="exampleLightbox" tabindex="-1" aria-labelledby="exampleLightboxLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleLightboxLabel">Factory Images</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div id="lightboxExampleCarousel" class="carousel slide">
                                <div class="carousel-inner ratio ratio-16x9 bg-dark">
                                    <div class="carousel-item text-center active">
                                        <img src={renovation1} alt="" class="img-fluid mh-100"/>
                                    </div>
                                    <div class="carousel-item text-center">
                                        <img src={renovation2} alt="" class="img-fluid mh-100"/>
                                    </div>
                                    <div class="carousel-item text-center">
                                        <img src={renovation3} alt="" class="img-fluid mh-100"/>
                                    </div>
                                    <div class="carousel-item text-center">
                                        <img src={renovation4} alt="" class="img-fluid mh-100"/>
                                    </div>
                                    <div class="carousel-item text-center">
                                        <img src={renovation5} alt="" class="img-fluid mh-100"/>
                                    </div>
                                    <div class="carousel-item text-center">
                                        <img src={renovation6} alt="" class="img-fluid mh-100"/>
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#lightboxExampleCarousel" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#lightboxExampleCarousel" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </div>

      <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
                    <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
</div>
   </>
  );
};
export default Factory;
