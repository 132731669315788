// Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';

const Vertical = () => {
  return (
    <>
  <div className='vertical'>
  <Link to="/gallery"> <button class = "btn btn-primary position-fixed top-50 vertical-text px-2 py-3 rounded rounded-start-0 shadow vertical-button">Completed Projects <i class="bi bi-arrow-down-circle-fill"></i> </button></Link>
  </div>
    </>
  );
};
export default Vertical;
