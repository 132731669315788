import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import interior from '../images/home/cookscape-interior-designs.webp';
import blog21 from '../images/blogs/blog-images/cookscape-blog-21 .webp';
import blog22 from '../images/blogs/blog-images/cookscape-blog-22 .webp';
import blog23 from '../images/blogs/blog-images/cookscape-blog-23 .webp';
import blog24 from '../images/blogs/blog-images/cookscape-blog-24 .webp';
import blog25 from '../images/blogs/blog-images/cookscape-blog-25 .webp';
import blog26 from '../images/blogs/blog-images/cookscape-blog-16 .webp';
import blog27 from '../images/blogs/blog-images/cookscape-blog-27 .webp';
import blog28 from '../images/blogs/blog-images/cookscape-blog-28 .webp';
import blog29 from '../images/blogs/blog-images/cookscape-blog-29 .webp';
import blog30 from '../images/blogs/blog-images/cookscape-blog-30 .webp';

import arangaiyar from '../images/blogs/architects/Cookscape--Architect-angaiyarkanni .webp';
import arkiruthiga from '../images/blogs/architects/Cookscape--Architect-keerthika .webp';
import arviswa from '../images/blogs/architects/Cookscape-Architect-Viswanath .webp';
import arsabari from '../images/blogs/architects/Cookscape-Architect-Sabarinathan .webp';
import arashika from '../images/blogs/architects/Cookscape-Architect-Aashika .webp';
import aranu from '../images/blogs/architects/Cookscape-Architect-Anulekha .webp';
import arfathima from '../images/blogs/architects/Cookscape-Architect-Moizu-Fathima .webp';
import argautham from '../images/blogs/architects/Cookscape-Architect-Gautham-Lakshmikanthan .webp';
import arsaranya from '../images/blogs/architects/Cookscape-Architect-Saranya .webp';
import arraakesh from '../images/blogs/architects/Cookscape--Architect-raakesh-natarajan .webp';
function Blog3() {
return (
<>
<Helmet>
<title> Cookscape - Interior Designer in Chennai, Modular Kitchen Designer in Chennai, Wardrobe Designer in Chennai, Home Renovation in Chennai, TV Unit in  Chennai, Kitchen Cabinet Designs In Chennai  </title>
<meta name="keywords" content="interior designers in Chennai, modular kitchen in Chennai, interior decorators in Chennai, kitchen designers in Chennai, bedroom wardrobe designers in Chennai, home renovation in Chennai, remodeling in Chennai, kitchen renovators in Chennai, living room interior decor in Chennai, dining room interior designers in Chennai, home interior decors in Chennai, interior design company in Chennai, interior design ideas in Chennai, kitchen interior ideas in Chennai, pooja room interior designers in Chennai, kitchen cabinet designs in Chennai"/>
<meta name="description" content="Cookscape offers expert interior design services, including modular kitchens, pooja units, wardrobes, TV units, kids' furniture, and more.
"/>
<meta name='copyright' content='Cookscape'/>
<meta name='language' content='IN'/>
<meta name='robots' content='index,follow'/>
<meta name='topic' content='Business'/>
<meta name='Classification' content='Interior Designer'/>
<meta name='author' content='info@cookscape.com'/>
<meta name='designer' content='Cookscape India Private Limited'/>
<meta name='reply-to' content='Cookscape'/>
<meta name='owner' content='Cookscape India Private Limited'/>
<link rel="canonical" href="https://www.cookscape.com/blog-3"></link>
</Helmet>
<div className="container-fluid services-breadcrumb">
        <h3 className="cookscape-services-title">Cookscape Blog</h3>
</div>
<div className="container-fluid background">
        <div className="container">
        <div className="blog-row p-3">
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog21} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'>How can I design the interior of my home with a low budget?</h1>
                <p className='blog-para'> If you are a person who spends the entire week at work, it will take many weekends to successfully execute an interior design project. This is a very long term commitment if the scope of work is large. If your work is not up to par and you have to redo an aspect of your interiors it adds to the cost.</p>
                <Link to="/how-can-i-design-the-interior-of-my-home-with-a-low-budget" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={arangaiyar} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Angaiyarkanni</h1>
              </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog22} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> What is the theme & concept in interior design?</h1>
                <p className='blog-para'> In inside planning the idea of “toning it down would be ideal” is tied in with making straightforward and effective insides. Straightforwardness is the way to accomplishing this. Perhaps the best thing about enlivening a house is the capacity to fit it to your own taste and fashion awareness. </p>
                <Link to="/what-is-the-theme-concept-in-interior-design" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={arkiruthiga} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Kiruthiga</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog23} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> 
                Easy step to plan your kitchen very modular!</h1>
                <p className='blog-para'> Generally we want kitchen very modular for our convenience and aesthetic. For example; in a normal kitchen we keep things where the space is available but in a modular kitchen we can allot a space as per our convenience.</p>
                <Link to="/easy-step-to-plan-your-kitchen-very-modular" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={arviswa} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Viswanath</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog24} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> How is interior wall thickness determined?</h1>
                <p className='blog-para'> When it carries plumbing or Electrical lines the thickness should be 150mm minimum or 230mm maximum as regular wall and if it is a load bearing supporting wall with utilities then it should be definitely 230mm.</p>
                <Link to="/how-is-interior-wall-thickness-determined" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={arsabari} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Sabarinathan</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog25} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> What is calypso variant?</h1>
                <p className='blog-para'> These Rugged and Robust frames, handles & post formed laminated shutters transform the space and revamps the ambience effortlessly. The sight of this variant is exceptionally alluring and it is also extremely durable.</p>
                <Link to="/what-is-calypso-variant" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={arashika} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Aashika</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog26} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> What is Suede Variant?</h1>
                <p className='blog-para'> his variant has a luxurious appeal. Its matte style finish with textures makes it a good choice for any space be it kitchen or any other space. It gives a unique look to the woodwork of a space that is equal part appealing as well as easy to maintain.</p>
                <Link to="/what-is-suede-variant" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={aranu} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Anulekha</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog27} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> What is Amaryllis Variant?</h1>
                <p className='blog-para'> Lustrous, handle-less, lacquered glass, frames and accessories are all set to breathe new life into interiors, transforming them into elegant showcases of beauty. </p>
                <Link to="/what-is-amaryllis-variant" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={arsaranya} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Saraniya  </h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog28} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> What is Veneer Variant?</h1>
                <p className='blog-para'> Veneer gives a natural look with perfectly smooth and decorative finish that reflects the natural beauty and elegance of fine wood. Natural patterns give an organic and high end look. It comes with PVC Edge Banding that protects its corners and keeps the laminate in place.
                </p>
                <Link to="/what-is-veneer-variant" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={arfathima} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Moizu Fathima</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog29} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> What is Iris Variant?</h1>
                <p className='blog-para'> Smooth texture and extra glossy finish is the USP of Iris. Available in a wide range of colors, this variant creates vibrant and dynamic spaces. Its internal carcass is made up of Boiling Water Proof material and the exterior shutter has 2mm thick acrylic sheet on it and that is covered with 3mm thick tempered glass.</p>
                <Link to="/what-is-iris-variant" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={argautham} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Gautham Lakshmikanthan</h1>
                </div>
            </div>
            <div className="row p-3">
                <div className="col-md-3">
                <img src={blog30} alt="" class="blog-img"/>
                </div>
                <div className="col-md-6">
                <h1 className='blog-title pt-3'> What is Classic Variant?</h1>
                <p className='blog-para'> It’s an attractive range of traditional profiles and designs rendered in five pieces of framed hardwood shutter. They often feature neutral color palette and intricate details which make the kitchen timeless.</p>
                <Link to="/what-is-classic-variant" ><button class="aboutus-button-2">View more</button></Link>
                </div>
                <div className="col-md-3">
                <img src={arraakesh} alt="" class="ar-img"/>
                <h1 className='blog-title pt-3'> Architect Raakesh Natarajan</h1>
                </div>
            </div>
            </div>
        </div>
        <div className='container'>
    <div className='row'>
        <div className='col-md-2'></div>
        <div className='col-md-8'>
        <div class="pagination  align-items-center">
  <Link to="/blog" > <a>&laquo;</a></Link>
  <Link to="/blog" >  <a>1</a></Link>
  <Link to="/blog-2" > <a>2</a></Link>
  <Link to="/blog-3" > <a class="active">3</a></Link>
  <Link to="/blog-4" > <a>4</a></Link>
  <Link to="/blog-5" > <a>5</a></Link>
  <Link to="/blog-6" > <a>6</a></Link>
  <Link to="/blog-7" > <a>7</a></Link>
  <Link to="/blog-8" > <a>8</a></Link>
  <Link to="/blog-8" ><a>&raquo;</a></Link>
    </div>
        </div>
        <div className='col-md-2'></div>
    </div>
</div>
</div>
<div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
                    <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
</div>
</>
  )
}

export default Blog3;