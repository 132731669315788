import React from 'react';
import { Link } from 'react-router-dom';
import interior from '../images/home/cookscape-interior-designs.webp';

const NotFound = () => {
  return (
    <>
    <div>
      <div className="container-fluid background">
      <h1 className='page-not-found text-center'>This page doesn't seem to exist.</h1>
     <div className="page-not-found-button text-center">
                           <Link to="/customer-speaks" target="_blank" rel="noopener noreferrer"> <button className="aboutus-button-1">View Customers Speaks</button> </Link>
                           <Link to="/gallery" target="_blank" rel="noopener noreferrer">    <button className="aboutus-button-2">Completed Projects</button> </Link>
                         </div>
      </div>
    </div>
    <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
      <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
        <div className="contact-form-container">
         <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                <div class="row">
                                <div class="col-12">
                                    <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                        Your message was sent successfully.
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 form-group">
                                    <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                </div>
                                <div class="col-md-12 form-group">
                                    <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                </div>
                                <div class="col-md-12 form-group">
                                    <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                </div>
                                <div class="col-12">
                                    <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                </div>
                            </div>
                </form>
              </div>
        </div>
        
      </div>
    </div>
</div>
</>
  );
};

export default NotFound;
