import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import interior from '../images/home/cookscape-interior-designs.webp';
import orbix from '../images/groupwebsites/Orbix .webp';
import omr from '../images/groupwebsites/omrhome .webp';
import walltowall from '../images/groupwebsites/walltowall .webp';
const Groupwebsites = () => {
  return (
    <>
<Helmet>
<title> Cookscape - Interior Designer in Chennai, Modular Kitchen Designer in Chennai, Wardrobe Designer in Chennai, Home Renovation in Chennai, TV Unit in  Chennai, Kitchen Cabinet Designs In Chennai  </title>
<meta name="keywords" content="interior designers in Chennai, modular kitchen in Chennai, interior decorators in Chennai, kitchen designers in Chennai, bedroom wardrobe designers in Chennai, home renovation in Chennai, remodeling in Chennai, kitchen renovators in Chennai, living room interior decor in Chennai, dining room interior designers in Chennai, home interior decors in Chennai, interior design company in Chennai, interior design ideas in Chennai, kitchen interior ideas in Chennai, pooja room interior designers in Chennai, kitchen cabinet designs in Chennai"/>
<meta name="description" content="Cookscape offers expert interior design services, including modular kitchens, pooja units, wardrobes, TV units, kids' furniture, and more.
"/>
<meta name='copyright' content='Cookscape'/>
<meta name='language' content='IN'/>
<meta name='robots' content='index,follow'/>
<meta name='topic' content='Business'/>
<meta name='Classification' content='Interior Designer'/>
<meta name='author' content='info@cookscape.com'/>
<meta name='designer' content='Cookscape India Private Limited'/>
<meta name='reply-to' content='Cookscape'/>
<meta name='owner' content='Cookscape India Private Limited'/>
<link rel="canonical" href="https://www.cookscape.com/group-websites"></link>
</Helmet>
<div className="container-fluid services-breadcrumb">
        <h3 className="cookscape-services-title">Cookscape Group Websites</h3>
</div>
<div className='container-fluid background'>
      <div className='container'>
        <div className='row websites'>
          <div className='col-md-6'>
              <div className='group'>
                  <img src={orbix} alt="orbix-designs" class="img-fluid"/>
                </div>
          </div>
          <div className='col-md-6'>
          <p class="website-title pt-5">ORBIX DESIGNS</p>
                <h1 class="website-title-main">Interior Design</h1>
                <p class="website-para">Orbix Designs is a leading one-stop solution Interior Design Company in Chennai Including all of South India. Our experience spans over 3 decades, and thousands of projects & Counting. We serve only the best of the best in Interior Design to all our clients at an affordable rate.</p>
                <Link to="https://orbixdesigns.com/"> <button class="aboutus-button-2">View more</button>  </Link>
          </div>
        </div>
        <div className='row websites'>
          <div className='col-md-6'>
          <p class="website-title pt-5">OMR HOMES INTERIORS</p>
                <h1 class="website-title-main">Interior Design</h1>
                <p class="website-para">OMR Homes is a trusted name in interior design, offering comprehensive solutions across Chennai. With a rich history of delivering excellence and a portfolio of thousands of completed projects, we pride creating exceptional spaces. We strive to incorporate high-quality designs with affordable prices for all our customers, thereby delivering the best service and value. Our services cover a single-room transformation to complete home redesigning with expert precision and finesse.</p>
                <Link to="https://omrhomes.com/">   <button class="aboutus-button-2">View more</button> </Link>
          </div>
          <div className='col-md-6'>
          <div className='group'>
                  <img src={omr} alt="omr-homes" class="img-fluid"/>
                </div>
          </div>
        </div>
          <div className='row websites'>
          <div className='col-md-6'>
          <div className='group'>
                  <img src={walltowall} alt="walltowall-home-decor" class="img-fluid"/>
                </div>
          </div>
          <div className='col-md-6'>
          <p class="website-title pt-5">WALL TO WALL HOME DECOR</p>
                <h1 class="website-title-main">Home Decor</h1>
                <p class="website-para">Wall to Wall is one of the most reliable suppliers of custom interior solutions within categories such as curtains, wall panels, home decor, wallpapers, and many more. Our products are high in quality and can add great beauty to your place, along with performing properly within that space. We have the most closely knit relationship with customers that provides personalized solutions that will suit every style and budget. Whether it's just a single room or an overhaul of the entire space, Wall to Wall will bring your ideas to life with care and creativity.</p>
              <Link to="https://homeinteriorandbeyond.com/" target="_blank" rel="noopener noreferrer"> <button class="aboutus-button-2">View more</button></Link>
          </div>
        </div>
      </div>
</div>
<div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
                    <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
</div>
    </>
  );
};

export default Groupwebsites;
