import React from 'react';
import { Helmet } from 'react-helmet';
import interior from '../images/home/cookscape-interior-designs.webp';
import blog64 from '../images/blogs/blog-images/cookscape-blog-64 .webp';
import arvasuki from '../images/blogs/architects/Cookscape-Architect-Vasuki-Kathirvel .webp';
const Blog7e = () => {
        return (
                <>
<Helmet>
<title> Cookscape - Interior Designer in Chennai, Modular Kitchen Designer in Chennai, Wardrobe Designer in Chennai, Home Renovation in Chennai, TV Unit in  Chennai, Kitchen Cabinet Designs In Chennai  </title>
<meta name="keywords" content="interior designers in Chennai, modular kitchen in Chennai, interior decorators in Chennai, kitchen designers in Chennai, bedroom wardrobe designers in Chennai, home renovation in Chennai, remodeling in Chennai, kitchen renovators in Chennai, living room interior decor in Chennai, dining room interior designers in Chennai, home interior decors in Chennai, interior design company in Chennai, interior design ideas in Chennai, kitchen interior ideas in Chennai, pooja room interior designers in Chennai, kitchen cabinet designs in Chennai"/>
<meta name="description" content="Cookscape offers expert interior design services, including modular kitchens, pooja units, wardrobes, TV units, kids' furniture, and more.
"/>
<meta name='copyright' content='Cookscape'/>
<meta name='language' content='IN'/>
<meta name='robots' content='index,follow'/>
<meta name='topic' content='Business'/>
<meta name='Classification' content='Interior Designer'/>
<meta name='author' content='info@cookscape.com'/>
<meta name='designer' content='Cookscape India Private Limited'/>
<meta name='reply-to' content='Cookscape'/>
<meta name='owner' content='Cookscape India Private Limited'/>
<link rel="canonical" href="https://www.cookscape.com/ideas-to-incorporate-a-veg-fruit-garden-in-your-home-interior-design"></link>
</Helmet>
                <div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
                </div>
                <div className='container-fluid background'>
                    <div className='container p-5'>
                          <h1 className='blog-page-title pt-3'>IDEAS TO INCORPORATE A VEG & FRUIT GARDEN IN YOUR HOME INTERIOR DESIGN</h1>
                          <img src={blog64} alt="cookscape-garden-ideas" class="blog-img-inside pt-3"/>
                          <p className='blog-page-para'>GARDEN is the space for display and cultivation. The main purpose of cultivating the fruits and vegetables in our home is to have Organic foods, as well; It is fun to have these activities in our indoor. To get the start for the indoor vegetable gardening, we could go with the pot having drainage holes and specially designed indoor potting soil. Some of the planters require the deep container to flourish the roots.</p>
                          <p className='blog-page-para'> Once you have got your suppliers, find a sunny window (veggies will need at least 4-6 hours of sunshine while the fruits will need at least 8-10 hours daily). And set up with the cool mist humidifier near an indoor garden to help stimulate their typical outdoor conditions and to prevent them from drying out.</p>
                          <p className='blog-page-para'> Some of the Indoor planting vegetables are Scallion, Radishes, micro greens, tomatoes, potatoes, strawberries and spinach. </p>
                          <p className='blog-page-para'> In many houses, space below the stairs is wasted. This space can be utilized better and could be a better space for the small garden. Pebbles, rocks and flowering water can add visual and textural interest to this space. To ensure, however, that these plants do get some amount of sunlight. Typically this area would not have access to much light. If there isn’t much sunlight, they should plan to have a couple of sun lamps in this area. And these lights should be kept at least 2 feet over the plants.</p>
                          <p className='blog-page-para'> When planning a vertical garden, we should give some thought to how the water will drain out. Plants that can handle more water can be grown in the flower pots as the water from the pots above will drain into the rows below.</p>
                          <p className='blog-page-para'> Some veggies that grow well indoors are radishes, turnips, lettuce, greens, and carrots. They are non-fussy and require minimal care. There are great benefits of growing indoor plants in our homes. Not only do they look pretty, but they also clean the air, absorb indoor heat and add humidity.</p>
                          <img src={arvasuki} alt="Architect-Vasuki-Kathirvel" class="blog-page-author-img pt-3"/>
                          <h1 className='blog-title pt-3'>Architect Vasuki Kathirvel</h1>
                      </div>
                  </div>
  <div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
                    <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
                </>
              );
            };
export default Blog7e;
