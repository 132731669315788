// Navbar.js
import React from 'react';

const Floating = () => {
  return (
    <>
  <div class="adminActions">
	<input type="checkbox" name="adminToggle" class="adminToggle" />
	<a class="adminButton" href="#!"><i class="fa fa-address-book"></i></a>
	<div class="adminButtons">
		<a href="https://web.whatsapp.com/send?phone=919600005679&text="><i class="fa fa-whatsapp round"></i></a>
		<a href="mailto:info.orchiddesigns@gmail.com"><i class="fa fa-envelope round"></i></a>
		<a href="tel:960005679"><i class="fa fa-volume-control-phone round"></i></a>
	</div>
</div>
    </>
  );
};
export default Floating;
