import React from 'react';
import { Helmet } from 'react-helmet';
import interior from '../images/home/cookscape-interior-designs.webp';
import blog43 from '../images/blogs/blog-images/cookscape-blog-43 .webp';
import arsaranya from '../images/blogs/architects/Cookscape-Architect-Saranya .webp';
const Blog5c = () => {
return (
<>
<Helmet>
<title> Cookscape - Interior Designer in Chennai, Modular Kitchen Designer in Chennai, Wardrobe Designer in Chennai, Home Renovation in Chennai, TV Unit in  Chennai, Kitchen Cabinet Designs In Chennai  </title>
<meta name="keywords" content="interior designers in Chennai, modular kitchen in Chennai, interior decorators in Chennai, kitchen designers in Chennai, bedroom wardrobe designers in Chennai, home renovation in Chennai, remodeling in Chennai, kitchen renovators in Chennai, living room interior decor in Chennai, dining room interior designers in Chennai, home interior decors in Chennai, interior design company in Chennai, interior design ideas in Chennai, kitchen interior ideas in Chennai, pooja room interior designers in Chennai, kitchen cabinet designs in Chennai"/>
<meta name="description" content="Cookscape offers expert interior design services, including modular kitchens, pooja units, wardrobes, TV units, kids' furniture, and more.
"/>
<meta name='copyright' content='Cookscape'/>
<meta name='language' content='IN'/>
<meta name='robots' content='index,follow'/>
<meta name='topic' content='Business'/>
<meta name='Classification' content='Interior Designer'/>
<meta name='author' content='info@cookscape.com'/>
<meta name='designer' content='Cookscape India Private Limited'/>
<meta name='reply-to' content='Cookscape'/>
<meta name='owner' content='Cookscape India Private Limited'/>
<link rel="canonical" href="https://www.cookscape.com/what-services-does-cookscape-offer"></link>
</Helmet>
<div className="container-fluid services-breadcrumb">
                    <h3 className="cookscape-services-title">Cookscape Blog</h3>
</div>
<div className='container-fluid background'>
                    <div className='container p-5'>
                        <h1 className='blog-page-title pt-3'>What services does Cookscape offer?</h1>
                        <img src={blog43} alt="cookscape-interior-design-services" class="blog-img-inside pt-3"/>
                        <p className='blog-page-para'>From the term Cookscape, itself it’s clear that it’s related to kitchen or cooking space and it does not needs any introduction in between people in this field in Chennai. Spanning a Legacy of over 20 years, Cookscape has positioned itself as a one point solution for best Modular kitchens in Chennai and a few nearby cities, and it provides same quality for every other requirement that you might have related to your home interiors.</p>
                        <p className='blog-page-para'>Right from creating the concept, to executing it as a living breathing space for your comfortable stay, Cookscape takes care of everything! As it undertakes Turnkey projects alone. </p>
                        <p className='blog-page-para'>Cookscape not only focuses  in  giving  the  aesthetic  look to the</p>
                        <p className='blog-page-para'>home but its main priorityis the quality of product used , All the options available in finishes with Cookscape are composition of best brands for individual component . Cooks cape’s Products comes with 10 years registered replacement guarantee certificate , which is government authorised and can be effectively used for the period of 10 years for claiming any services required.</p>
                        <p className='blog-page-para'>Cookscape Is having their own specialised architects who doesn’t only guide clients with design ideas , but also suggest them the best material according to the usage of the space , with this they make sure that client has clearly understood the product and is well knowledged about what they are getting.</p>
                        <h2 className='blog-page-title-2 pt-3'>Services provided by Cookscape can be listed out as following:</h2>
                        <p className='blog-page-para'>1. Modular work: Includes Kitchen, wardrobes, TV, Pooja, crockery and etc fixed wood work.</p>
                        <p className='blog-page-para'>2. On site work: False ceiling, wall paper, painting, etc including civil, plumbing or electrical work required as per interior design.</p>
                        <p className='blog-page-para'>3. furniture’s and Add-ons : Accessories , Dining table , bed , cot , curtain , curtain rod etc , small minute things to enhance the aesthetic of the space.</p>
                        <h2 className='blog-page-title-2 pt-3'>The above mentioned are services for which you can reach Cookscape.</h2>
                        <h2 className='blog-page-title-2 pt-3'>Once you start discussion with Cookscape, following services you receive.</h2>
                        <p className='blog-page-para'>1. A clear one to one discussion with an architect, to discuss your requirement and a detailed tour of showroom explaining the Pros and cons of individual options available in finishes.</p>
                        <p className='blog-page-para'>2. Meeting at site with an engineer to check on the space and how better it can be used.</p>
                        <p className='blog-page-para'>3. Chance to customize minute things like shelves that are going to cum inside your wardrobe in a presence of an architect so that you can understand the effectiveness of that customization and compatibility in using it once it’s at site.</p>
                        <p className='blog-page-para'>4. Friendly and homely atmosphere in showroom, for every customer’s satisfaction.</p>
                        <p className='blog-page-para'>5. Compatibility of individual architect so that customer is able to have a discussion in the time they are available and not have to rush here and there.</p>
                        <p className='blog-page-para'>6. Even the site supervisor and carpenters will be helping you with their best during installation at site.</p>
                        <p className='blog-page-para'>Once the installation at site is completed, usually clients thinks interior persons responsibility ends their but no with Cookscape there are services available from our side after installation as following.</p>
                        <p className='blog-page-para'>1. Within 15 days of the installation our supervisor come again at site and help with the hardware alignment , because once we start using and hardware gets worked up , it either gets too tight or too loose , so for proper alignment we send person to site and check ourselves.</p>
                        <p className='blog-page-para'>2. Once above service is done, clients receive their certificate of guarantee.</p>
                        <p className='blog-page-para'>3. For first three years we itself call our clients to fix up yearly service, after that clients can reach us any time for any service required.</p>
                        <p className='blog-page-para'>So after the installation is done also our responsibility is not over and we are responsible for any service required for the completed work.</p>
                        <p className='blog-page-para'>So why to choose Cookscape?</p>
                        <p className='blog-page-para'>“Cookscape Interior and Modular Kitchen comes with a manufacturing guaranty of 10 Years as we exceed in the industry standards for quality of material and workmanship enduring the best after services in the industries”</p>
                        <img src={arsaranya} alt="Architect-Saranya" class="blog-page-author-img pt-3"/>
                        <h1 className='blog-title pt-3'>Architect Saranya</h1>
                      </div>
</div>
<div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
                    <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
</div>
</>
              );
            };
export default Blog5c;
