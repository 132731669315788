import React from 'react';
import { Helmet } from 'react-helmet';
import interior from '../images/home/cookscape-interior-designs.webp';
import bunkerbeds from '../images/services/kids/cookscape-bunker-cots.webp';
import cribsandcots from '../images/services/kids/cookscape-cribs-and-cots .webp';
import murphybeds from '../images/services/kids/cookscape-murphy-beds .webp';
import toddlerbeds from '../images/services/kids/cookscape-toddler-beds .webp';
const Kidsfurniture = () => {
  return (
    <>
<Helmet>
<title> Cookscape - Best Kids Furniture Designer in Chennai</title>
<meta name="keywords" content="Kids Furniture in Chennai, Kids Furniture decorators in Chennai, Kids Furniture designers in Chennai, Kids Furniture bedroom wardrobe designers in Chennai, Kids Furniture renovation in Chennai, Kids Furniture remodeling in Chennai, Kids Furniture renovators in Chennai, Kids Furniture living room interior decor in Chennai,  Kids Furniture decors in Chennai"/>
<meta name="description" content="Build a dream room for your child with custom kids' furniture by Cookscape Interiors. Safe, stylish designs that fit your budget and space perfectly.
"/>
<meta name='copyright' content='Cookscape'/>
<meta name='language' content='IN'/>
<meta name='robots' content='index,follow'/>
<meta name='topic' content='Business'/>
<meta name='Classification' content='Interior Designer'/>
<meta name='author' content='info@cookscape.com'/>
<meta name='designer' content='Cookscape India Private Limited'/>
<meta name='reply-to' content='Cookscape'/>
<meta name='owner' content='Cookscape India Private Limited'/>
<link rel="canonical" href="https://www.cookscape.com/kids-furniture"></link>
</Helmet>
<div className="container-fluid services-breadcrumb">
            <h3 className="cookscape-services-title">Cookscape Kids Furniture Ideas</h3>
</div>
<div className="container-fluid background">
        <div className="container ">
            <h1 className="interior-title p-3">Kids Furniture Designs</h1>
            <p className="interior-para">Selecting furniture for your child’s room plays a critical role in creating a well-organized, safe, and fun room. Children's furniture comes in many designs, styles, colors, and sizes, ranging from cribs for babies to desks for teenagers. This section presents the widely used kinds of children’s furniture intended to fit various requirements and desires.</p>
        </div>
        <div className="container p-3">
            <div className="row">
                <div className="col-md-3">
                    <div className="interior-col">
                        <img src={bunkerbeds} alt="" class="img-fluid rounded"/>
                        <h1 className="interior-title pt-3">Bunk Beds</h1>
                        <p className="interior-para p-3">Bunk beds are the smart solution for shared bedrooms, especially for siblings. Versatile beds come in different designs, from classic wooden frames to modern metal structures, often equipped with practical features like built-in storage, study desks, or playful additions such as slides.</p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="interior-col">
                        <img src={murphybeds} alt="" class="img-fluid rounded"/>
                        <h1 className="interior-title pt-3">Loft Beds</h1>
                        <p className="interior-para p-3">Loft beds suit teenagers or older kids who need a creative and organized space. Elevated from the ground, these beds offer room for a study desk, bookshelf, or cozy seating area. Loft beds are an excellent choice for smaller rooms, combining functionality with style to make the most of every square foot.</p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="interior-col">
                        <img src={cribsandcots} alt="" class="img-fluid rounded"/>
                        <h1 className="interior-title pt-3">Cribs and Cots</h1>
                        <p className="interior-para p-3">Cribs should not miss newborns, providing safe sleep areas for a night of undisturbed sleep. Most cribs convert to a toddler bed so they can serve long. Lightweight and portable cots are ideal for moving parents because they make it easy but don't compromise your baby's comfort.</p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="interior-col">
                        <img src={toddlerbeds} alt="" class="img-fluid rounded"/>
                        <h1 className="interior-title pt-3">Toddler Beds</h1>
                        <p className="interior-para p-3">Toddler beds are safe and practical options when it's time to transition from a crib. With safety rails, toddler beds are ideal for young children transitioning to sleeping alone. These beds are low to the ground and come in fun designs, making them both safe and inviting for little ones.</p>
                    </div>
                </div>
            </div>
    </div>
</div>
<div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
                    <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
</div>
    </>
  );
};
export default Kidsfurniture;
