import React from 'react';
import { Helmet } from 'react-helmet';
import interior from '../images/home/cookscape-interior-designs.webp';
import poojaunit1 from '../images/services/poojaunit/cookscape-floor mounted pooja unit .webp';
import poojaunit2 from '../images/services/poojaunit/cookscape-wall mounted pooja unit .webp';
import poojaunit3 from '../images/services/poojaunit/cookscape-pooja room .webp';
import poojaunit4 from '../images/services/poojaunit/cookscape-portable pooja unit.webp';
const Poojaunit = () => {
  return (
    <>
<Helmet>
<title> Cookscape - Pooja unit In Chennai | Pooja unit Designers In Chennai | Poojaunit Design Ideas Chennai | Pooja unit Designs In Chennai | Pooja unit Manafacturer Chennai | Pooja unit Showroom Near Me | Pooja unit Interior Designs In Chennai | Pooja unit Renovation Chennai | Pooja unit Remodeling Contractors In Chenani </title>
<meta name="keywords" content="Pooja unit In Chennai, Pooja unit Designers In Chennai, Poojaunit Design Ideas Chennai, Pooja unit Designs In Chennai, Pooja unit Manafacturer Chennai, Pooja unit Showroom Near Me, Pooja unit Interior Designs In Chennai, Pooja unit Renovation Chennai, Pooja unit Remodeling Contractors In Chenani"/>
<meta name="description" content="Cookscape Interiors offers pooja units that create a serene and peaceful space, enhancing your home’s calm atmosphere.
"/>
<meta name='copyright' content='Cookscape'/>
<meta name='language' content='IN'/>
<meta name='robots' content='index,follow'/>
<meta name='topic' content='Business'/>
<meta name='Classification' content='Interior Designer'/>
<meta name='author' content='info@cookscape.com'/>
<meta name='designer' content='Cookscape India Private Limited'/>
<meta name='reply-to' content='Cookscape'/>
<meta name='owner' content='Cookscape India Private Limited'/>
<link rel="canonical" href="https://www.cookscape.com/poojaunit"></link>
</Helmet>
<div className="container-fluid services-breadcrumb">
            <h3 className="cookscape-services-title">Cookscape Pooja Unit Ideas</h3>
</div>
<div className="container-fluid background">
        <div className="container ">
            <h1 className="interior-title p-3">Pooja Unit Designs</h1>
            <p className="interior-para">A serene and organized space for all your daily rituals, Pooja units are a way of adding those dedicated compartments to place the idols, lamps, and other puja essentials. From a compact and contemporary design to a traditional carved mandir, the perfect Pooja unit fuses spirituality with style to bring harmony and welcoming vibes into your home.Connect with the best Pooja unit designer in Chennai for a divine and clutter-free setup. Devotional designs, tailor-made for you, are bound to go well with your home's interior design. Let us make the serene space that speaks to elegance in every detail.</p>
        </div>
        <div className="container p-3">
            <div className="row">
                <div className="col-md-3">
                    <div className="interior-col">
                        <img src={poojaunit1} alt="" class="img-fluid rounded"/>
                        <h1 className="interior-title pt-3">Floor-Mounted Pooja Unit </h1>
                        <p className="interior-para p-3">Floor-mounted pooja units are perfect for those who want a more traditional and earthy look. They provide enough space for idols, lamps, and other pooja accessories, which makes them a great choice for families that are particular about rituals. These pooja units are made of wood, marble, or other premium materials, which bring an element of elegance and spirituality to your home while fitting well with the decor.</p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="interior-col">
                        <img src={poojaunit2} alt="" class="img-fluid rounded"/>
                        <h1 className="interior-title pt-3">Wall-Mounted Pooja Unit </h1>
                        <p className="interior-para p-3">A wall-mounted pooja unit is the perfect solution for modern homes where space is a luxury. Compact yet elegant, these units offer a serene spiritual corner without taking up any floor space. With intricate carvings, backlit panels, or minimalistic designs, wall-mounted pooja units marry devotion with contemporary style. They also have compartments or shelves to keep pooja essentials neatly organized and clutter-free, making the space sacred.</p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="interior-col">
                        <img src={poojaunit3} alt="" class="img-fluid rounded"/>
                        <h1 className="interior-title pt-3">Pooja Room
                        </h1>
                        <p className="interior-para p-3">A dedicated pooja room is a time-trusted way to create a tranquil and spiritually charged sanctuary within your house. It serves as the heart of devotion in the house with intricately designed doors, ornate backdrops, and more than sufficient space for all pooja essentials. Modern pooja rooms come with soft lighting, elegant storage solutions, and personalized designs that reflect the faith and style of their owners. Whether compact or elaborate, a pooja room is sure to enhance the ambiance and spiritual energy within your house.</p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="interior-col">
                        <img src={poojaunit4} alt="" class="img-fluid rounded"/>
                        <h1 className="interior-title pt-3">Portable Pooja Unit</h1>
                        <p className="interior-para p-3">A portable pooja unit is perfect for today's lifestyles where flexibility can be the key. Lightweight, compact, and easy to move or relocate, the units are ideal for renters and homes with limited space. Without losing their mobility, they offer dedicated shelves and compartments for the idols, lamps, and pooja essentials. Available in contemporary and traditional designs, portable pooja units are an amalgamation of convenience and spirituality ensuring you have a sacred space anywhere you go.</p>
                    </div>
                </div>
            </div>
    </div>
</div>
<div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
                    <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
</div>
    </>
  );
};
export default Poojaunit;
