import React from 'react';
import { Helmet } from 'react-helmet';
import interior from '../images/home/cookscape-interior-designs.webp';
const Customerspeaks = () => {
  return (
    <>
<Helmet>
<title> Cookscape - Interior Designer in Chennai, Modular Kitchen Designer in Chennai, Wardrobe Designer in Chennai, Home Renovation in Chennai, TV Unit in  Chennai, Kitchen Cabinet Designs In Chennai  </title>
<meta name="keywords" content="interior designers in Chennai, modular kitchen in Chennai, interior decorators in Chennai, kitchen designers in Chennai, bedroom wardrobe designers in Chennai, home renovation in Chennai, remodeling in Chennai, kitchen renovators in Chennai, living room interior decor in Chennai, dining room interior designers in Chennai, home interior decors in Chennai, interior design company in Chennai, interior design ideas in Chennai, kitchen interior ideas in Chennai, pooja room interior designers in Chennai, kitchen cabinet designs in Chennai"/>
<meta name="description" content="Cookscape offers expert interior design services, including modular kitchens, pooja units, wardrobes, TV units, kids' furniture, and more.
"/>
<meta name='copyright' content='Cookscape'/>
<meta name='language' content='IN'/>
<meta name='robots' content='index,follow'/>
<meta name='topic' content='Business'/>
<meta name='Classification' content='Interior Designer'/>
<meta name='author' content='info@cookscape.com'/>
<meta name='designer' content='Cookscape India Private Limited'/>
<meta name='reply-to' content='Cookscape'/>
<meta name='owner' content='Cookscape India Private Limited'/>
<link rel="canonical" href="https://www.cookscape.com/customer-speaks"></link>
</Helmet>
<div className="container-fluid services-breadcrumb">
        <h3 className="cookscape-services-title">Cookscape Customer Speaks</h3>
    </div>
  <div className="container-fluid background">
    <div className="container ">
    <h1 className="interior-title p-3">See What Our Customers Say!</h1>
            <p className="interior-para">We don't just take your word for it; hear from the homeowners who have experienced the transformation firsthand! Our clients rave about how we turn their dreams into reality, creating spaces that are both beautiful and functional. From stylish renovations to thoughtful designs, we make sure every detail is perfect.
            <br/>
            <br/>
          Want to see magic for yourself? Look through our YouTube videos; Here, you will see true stories of our customers and magnificent transformations! See how we turn the vision into life in each room.</p>
    </div>
    <div className="container">
    <div className="row">
        <div className="col-md-4">
        <div class="embed-responsive embed-responsive-16by9 p-1">
        <iframe class="embed-responsive-item" target="_blank" rel="noopener noreferrer" src="https://www.youtube.com/embed/kr5V51F2ERU?si=MLcxFU0s3fTI438f" allowfullscreen></iframe>
        </div>
        </div>
        <div className="col-md-4">
        <div class="embed-responsive embed-responsive-16by9 p-1">
        <iframe class="embed-responsive-item" target="_blank" rel="noopener noreferrer" src="https://www.youtube.com/embed/dqtZVFNpYls?si=gecCmnErZL4RfrK3" allowfullscreen></iframe>
        </div>
        </div>
        <div className="col-md-4">
        <div class="embed-responsive embed-responsive-16by9 p-1">
        <iframe class="embed-responsive-item" target="_blank" rel="noopener noreferrer" src="https://www.youtube.com/embed/IoGsgTEI5NI?si=ttgGq_g64wiD87Fw" allowfullscreen></iframe>
        </div>
        </div>
       </div>
       <div className="row">
        <div className="col-md-4">
        <div class="embed-responsive embed-responsive-16by9 p-1">
        <iframe class="embed-responsive-item" target="_blank" rel="noopener noreferrer" src="https://www.youtube.com/embed/VWnpmxVqzGc?si=LKkp9P78AykPJBX4" allowfullscreen></iframe>
        </div>
        </div>

        <div className="col-md-4">
        <div class="embed-responsive embed-responsive-16by9 p-1">
        <iframe class="embed-responsive-item" target="_blank" rel="noopener noreferrer" src="https://www.youtube.com/embed/ExDvGH6P-TE?si=DXNSfeYLrslMrLxU" allowfullscreen></iframe>
        </div>
        </div>

        <div className="col-md-4">
        <div class="embed-responsive embed-responsive-16by9 p-1">
        <iframe class="embed-responsive-item" target="_blank" rel="noopener noreferrer" src="https://www.youtube.com/embed/YOfrf-BYONg?si=6LbGy0Vnqu8QMXzk" allowfullscreen></iframe>
        </div>
        </div>
       </div>

       

       
    </div>
    </div>
<div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
                    <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default Customerspeaks;
