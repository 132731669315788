// Navbar.js
import React from 'react';
import img1 from '../images/services/best-interior-design-2.jpg';
import img2 from '../images/services/design.jpg';
import interior from '../images/home/cookscape-interior-designs.webp';
const Services = () => {
  return (
    <>
    <div className="container-fluid services-breadcrumb">
        <h3 className="cookscape-services-title">Cookscape Services</h3>
</div>
    <div className='container'>
    <div className='row m-5'>
        <div className='col-md-6'>
        <img src={img1} alt="cookscape-interior-design-ideas"/>
        </div>
        <div className='round'>
                <h2 className="round-title">30+</h2>
                <p className='round-para'>Years Experience</p>
              </div>
        <div className='col-md-6'>
        <p className='work-para'>Interiors</p>
        <h2 className='service-page-title'>All you can think about
        Home Interiors Design</h2>
        <p className='service-page-para'>Cookscape is a well known brand in the field of manufacturing and marketing modern kitchens for the last 25 years, over the years, been widely recognized as a one-stop store for home interiors offering comprehensive solutions – from concept to execution. We offer the entire range of Well-designed, functional home furnishing solutions including modular kitchens, wardrobes, study units, and other modular woodwork, all designed to be functional and user-friendly.</p>
        </div>
    </div>
    </div>

    <div className='container-fluid work'>
  <div className='row'>
    <div className='col-md-3'>
      <p className='work-para'>HOW WE WORK</p>
      
      <h2 className='work-title'>3 Easy steps to get interior design</h2>
      <p className='work-para1'>Well-designed and customized interior designs can be obtained in 3 easy steps</p>
    </div>
    <div className='col-md-3'>
      <div className='work-image'>
      <p class="work-icon"><i class="fa fa-check-square-o" aria-hidden="true"></i></p>
        <h2 className='work-inside-title'>Explore Product</h2>
        <p className='work-inside-para'>Explore Our Projects & Meet design team</p>
      </div>
      </div>
      <div className='col-md-3'>
      <div className='work-image'>
      <p class="work-icon"><i class="fa fa-thumbs-up" aria-hidden="true"></i></p>
        <h2 className='work-inside-title'>Customize Design</h2>
        <p className='work-inside-para'>Customize Your Design & Hassle Free Installation</p>
      </div>
      </div>
      <div className='col-md-3'>
      <div className='work-image'>
      <p class="work-icon"><i class="fa fa-calendar" aria-hidden="true"></i></p>
        <h2 className='work-inside-title'>Place The Order</h2>
        <p className='work-inside-para'>Place The Order And Delivery In 45 Days</p>
      </div>
      </div>
  </div>

</div>

<div className='container'>
    <div className='row m-5'>
        <div className='col-md-6'>
        <img src={img2} alt="cookscape-interior-design-ideas"/>
        </div>
        
        <div className='col-md-6'>
        <p className='work-para'>Customized Designs</p>
        <h2 className='service-page-title'>Discover a new home in your own home</h2>
        <p className='service-page-para'>Cookscape is a one store solution for you, which is the only organized corporate company for kitchen renovation in Chennai. Cookscape kitchen comes to you as complete package of Modular Kitchen Price product and service. This means we take care of everything. From designing to manufacturing, installation and certification along with 10 years of Guarantee
        Renovation: Simple solution to modernize a kitchen from old to brand new modular kitchen.</p>
        </div>
    </div>
    </div>

<div class="modal fade" id="verticalModal" tabindex="-1" aria-labelledby="verticalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
          <img src={interior} alt="cookscape-interior-designs" class="img-fluid rounded pb-1"/>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Get free design Catalogue</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <div className="contact-form-container">
                    <form id="contactForm" method="post" action="https://www.cookscape.com/static/mail.php">
                    <div class="row">
                                    <div class="col-12">
                                        <div class="alert alert-success contact__msg_short" style={{display: "none"}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <input name="name" type="text" class="form-control" placeholder="Name" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="email" type="email" class="form-control" placeholder="Email" required/>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <input name="phone" type="text" class="form-control" placeholder="Phone" required/>
                                    </div>
                                    <div class="col-12">
                                        <input name="submit" type="submit" class="btn btn-success" value="Send Message"/>
                                    </div>
                                </div>
                    </form>
                  </div>
            </div>
            
          </div>
        </div>
</div>
   </>
  );
};
export default Services;
